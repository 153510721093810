<template>
  <label :class="{ checked: checked, disabled: disabled}">
    {{ label }}
    <input type="checkbox" v-model="checked" @change="handleChange($event.target.checked)" :disabled="disabled"/>
    <div class="visual-checkbox" aria-hidden="true"><span class="checkmark">✓</span></div>
  </label>
</template>

<script>
export default {
  name: 'MysticalCheckbox',
  data() {
    return {
      checked: false,
    };
  },
  emits: ['update:modelValue'],
  props: {
    value: Boolean,
    label: String,
    checkedMessage: String,
    disabled: Boolean
  },
  methods: {
    handleChange(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 2rem;
  position: relative;
  transition: filter 400ms ease;

  input {
    margin-top: 3rem;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }

  input:focus + .visual-checkbox {
    filter: brightness(1.1);
    transform: scale(1.1);
  }

  .visual-checkbox {
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--color-white);
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0.3rem;
    background: var(--color-purple-darken-1);
    pointer-events: none;
    color: var(--color-creamsicle);
    transition: filter 400ms ease, transform 400ms ease;

    .checkmark {
      position: relative;
      bottom: 15px;
      left: 0;
      font-weight: bold;
      font-size: 2.8rem;
      clip-path: polygon(-9.19% -29.41%, -41.59% 79.41%, 6.07% 123.52%);
      transition: clip-path 400ms ease;
    }
  }

  &.checked {
    .visual-checkbox {
      .checkmark {
        clip-path: polygon(-9.19% -29.41%, -41.59% 79.41%, 580.36% 146.28%);
      }
    }
  }

  &.disabled {
    .visual-checkbox {
      filter: grayscale(1);
    }
  }

  &:hover:not(.disabled) {
    .visual-checkbox {
      filter: brightness(1.1);
      transform: scale(1.1);
    }
  }
}
</style>
