<template>
  <TabContent :loading="loading" ref="tabContent">
    <div v-if="hasFriends">
      <h1>Create a Story</h1>
      <MysticalInput
        label="Story title:"
        :isBlankable="false"
        blankMessage="Your story needs a title!"
        v-model.trim="title"
        @input="handleFormDirtied()"
        :disabled="creatingStory"
      />
      <MysticalInput
        label="Participants:"
        :isBlankable="false"
        :suggestions="friendUserNames"
        blankMessage="You just need to add a friend or two. 😀"
        v-model="participants"
        @input="handleFormDirtied()"
        :disabled="creatingStory"
      />
      <MysticalTextArea
        label="The first sentence:"
        placeholder="Once upon a time..."
        :isBlankable="false"
        v-model="firstSentence"
        @input="handleFormDirtied()"
        :loading="loadingPlagiarism"
        :disabled="creatingStory"
      />
      <MysticalButton label="Plagiarize" @click="plagiarize()" :disabled="creatingStory" />
      <MysticalCheckbox
        label="Edgy:"
        :isBlankable="false"
        v-model="edgy"
        @update:modelValue="handleEdgyClick()"
        :disabled="creatingStory"
      />
      <transition name="message">
        <div class="checked-message" v-show="edgy" ref="message">
          <div class="icon-container">
            <img src="../assets/exclamation-triangle-solid.svg" alt="warning icon" />
          </div>
          <strong>{{
            "Whoa, you're not messing around!! Just keep in mind that turning on edgy mode will enable some more 'mature' writing challenges. This isn't recommended when playing with kids or small animals. You've been warned, buckaroo. 🤠"
          }}</strong>
        </div>
      </transition>
      <MysticalButton
        label="Create!"
        ref="button"
        @click="handleCreate()"
        :loading="creatingStory"
      />
    </div>
    <transition v-if="hasNoFriends" name="no-friends">
      <MysticalObject type="surprised" message="You need some friends to start a story.">
        <MysticalButton to="/friends" label="Check your friend requests" />
      </MysticalObject>
    </transition>
  </TabContent>
</template>

<script>
import { nextTick } from 'vue';
import TabContent from '../components/TabContent.vue';
import MysticalInput from '../components/MysticalInput.vue';
import MysticalTextArea from '../components/MysticalTextArea.vue';
import MysticalCheckbox from '../components/MysticalCheckbox.vue';
import MysticalButton from '../components/MysticalButton.vue';
import MysticalObject from '../components/MysticalObject.vue';
import { getFriendsForUser } from '../services/friendsService';
import { createStory, plagiarize } from '../services/storiesService';
import { showSuccess, showError } from '../services/toastService';
import { unsavedWorkMixin } from '../mixins/unsavedWorkPrompt';

export default {
  name: 'CreateStory',
  mixins: [unsavedWorkMixin],
  components: {
    TabContent,
    MysticalInput,
    MysticalTextArea,
    MysticalCheckbox,
    MysticalButton,
    MysticalObject,
  },
  data() {
    return {
      friends: [],
      participants: [],
      title: '',
      firstSentence: '',
      edgy: false,
      buttonOffset: 0,
      loading: true,
      hasNoFriends: false,
      hasFriends: false,
      loadingPlagiarism: false,
      creatingStory: false,
    };
  },
  computed: {
    friendUserNames() {
      return this.friends.map(friend => friend.username);
    },
  },
  methods: {
    handleEdgyClick() {
      this.handleFormDirtied();

      if (this.edgy) {
        // We need to get the height of the message box after it has fully rendered,
        // so we get it after the next vue tick
        nextTick(() => {
          this.buttonOffset = this.$refs.message.offsetHeight;

          this.$refs.button.$el.style.transform = `translateY(${this.buttonOffset}px)`;
          this.$refs.tabContent.$el.style.paddingBottom = `${this.buttonOffset + 10}px`;
        });
      } else {
        this.$refs.button.$el.style.transform = 'translateY(0)';
        this.$refs.tabContent.$el.style.paddingBottom = '2rem';
      }
    },
    calculateTotalRoundCount() {
      const balancedLengthFactor = Math.max(Math.floor(12 / this.participants.length), 1);
      const randomScale = Math.floor(Math.random() * 3) + 1;

      return balancedLengthFactor * randomScale * this.participants.length;
    },
    handleFormDirtied() {
      this.markComponentDirty();
    },
    handleCreate() {
      // TODO: there should be a way to tap into the validation logic of these inputs instead
      if (
        this.participants.length === 0 ||
        this.title.length === 0 ||
        this.firstSentence.length === 0
      ) {
        showError('Whoops! Looks like there are fields that need to be filled out above. 🤔');
      } else {
        this.creatingStory = true;

        // TODO: add ability to choose locale
        const storyBody = {
          name: this.title,
          locale: 'en-US',
          totalRoundCount: this.calculateTotalRoundCount(),
          users: this.participants,
          firstSubmission: this.firstSentence.trim(),
        };

        createStory(storyBody)
          .then(() => {
            this.markComponentClean();
            this.$router.push('/');
            showSuccess(
              `Nice! Your story, '${this.title}', has begun! We have notified the first player. 🤩`
            );
          })
          .catch(() => {
            showError('Hrm, an error occurred while creating your story.');
          })
          .finally(() => {
            this.creatingStory = false;
          });
      }
    },
    plagiarize() {
      this.loadingPlagiarism = true;

      plagiarize()
        .then(response => {
          this.firstSentence = response.content;
        })
        .catch(() => {
          showError("Maybe they're onto us! We couldn't load any lines...");
        })
        .finally(() => {
          this.loadingPlagiarism = false;
        });
    },
  },
  mounted() {
    getFriendsForUser(this.$username)
      .then(friends => {
        this.friends = friends;

        if (friends.length === 0) {
          this.hasNoFriends = true;
        } else {
          this.hasFriends = true;
        }
      })
      .catch(() => {
        showError('Uh oh! An error occurred while getting your list of friends. 🤨');
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.checked-message {
  background: var(--color-purple-darken-1);
  border-radius: 1rem;
  box-shadow: var(--purple-shadow);
  display: flex;
  padding: 3rem;
  align-items: center;
  margin-top: 1rem;
  margin-right: 2rem;
  position: absolute;

  .icon-container {
    background: var(--color-purple-darken-2);
    padding: 1rem;
    border-radius: 50%;
    max-width: 5rem;
    max-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
      position: relative;
      bottom: 0.2rem;
    }
  }

  strong {
    margin-left: 3rem;
    font-size: 1.8rem;
    font-weight: normal;
  }
}

.mystical-object {
  .button-wrapper {
    display: inline-block;
    margin: 1rem;
  }

  .arrow {
    width: 15vw;
    height: 21vh;
    position: absolute;
    right: 2vw;
    top: -2rem;
  }
}

.message-enter-active,
.message-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.message-enter-from,
.message-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}

.no-friends-leave-active,
.no-friends-enter-active {
  transition: transform 400ms ease, opacity 400ms ease;
}

.no-friends-leave-to,
.no-friends-enter {
  transform: translateX(10rem);
  opacity: 0;
}

@media (max-width: 600px) {
  .checked-message {
    flex-direction: column;

    strong {
      font-size: 1.5rem;
      margin-left: 0;
    }

    .icon-container {
      margin-bottom: 2rem;
    }
  }
}
</style>
